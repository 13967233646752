<template>
    <div class="homecontainer d-flex flex-column justify-content-center align-items-center mx-4" v-on:keyup.ctrl="doSomething">

        <div class="calendario d-flex justify-content-between w-100">
            <div class="flechaizq">
                <DxButton
                    @click="cambiaDia(-1)"
                    width="40"
                    :element-attr="{ class: 'btn-outline'}"
                    hint="Ir al día anterior | Shift + Flecha Izquierda"
                >
                    <i class="bi bi-chevron-left"></i>
                </DxButton>
            </div>

            <div class="fecha flex-grow-1 d-flex gap-2 justify-content-center align-items-center text-black">
                <div class="dia">
                    {{ dias[hoy.getDay()] }} -
                    {{ hoy.toLocaleDateString('es-AR',{ year : 'numeric', month : '2-digit', day : '2-digit' }) }}
                </div>
                <div class="ghostdata">
                    {{ ghost }}
                </div>
            </div>

            <div class="flechader"> 
                <DxButton
                    @click="cambiaDia(1)"
                    width="40"
                    :element-attr="{ class: 'btn-outline'}"
                    hint="Ir al día siguiente | Shift + Flecha Derecha"
                >
                    <i class="bi bi-chevron-right"></i>
                </DxButton>
            </div>
        </div>

    <div class="donut my-4 text-center" v-if="showPieChart">
    <DxPieChart
        ref="myDonut"
        :data-source="storeDonut"
        type="doughnut"
        :start-angle="90"
        :customize-point="customizePoint"
        :legend="{
                    visible: false
                }"
        center-template="centerTemplate"
        :size="{width:250 , height:250}"
        :inner-radius="0.7"
        >
        <DxSeries
            argument-field="project"
            value-field="time"
        >
        </DxSeries>
        <template #centerTemplate>
            <svg>
                <circle
                :r="5.3"
                cx="100"
                cy="100"
                fill="#eee"
                />
            <text 
                x="100" 
                y="110"
                text-anchor="middle" 
                class="centertext">
                {{ labelHoras }}
            </text>
            </svg>
        </template>
    </DxPieChart>


    </div>  <!-- Fin Donut -->

    <!-- Espacio entre dia si no hay Torta -->
    <div v-if="!showPieChart">
        <div class="my-4">
        </div>
    </div>


    <!-- GRILLA -->

    <div class="grilla w-100 overflow-auto" id="target">

    <!-- @selection-changed="selectionChanged" -->

    <DxDataGrid 
        id="datagrid"
        ref="myDataGrid"
        :data-source="masterDataSource"
        :show-borders="true"
        :hover-state-enabled="true"
        :selected-row-keys="selectedItemKeys"
        @context-menu-preparing="onShowing"
        @row-expanding="onRowExpanding"
        @focused-row-changed="onFocusedRowChanged"
        :sorting="{ mode: 'none'}"
    >

        <DxScrolling
            show-scrollbar="true"    
        >

        </DxScrolling>
        <DxSelection 
            mode="multiple"
            show-check-boxes-mode="none"
        /> 

        <DxToolbar>

            <DxItem
                location="before"
                :visible="!isVisibleTarea && !isVisibleProyecto  && !isVisiblePegarCalendar"
            >
                <DxButton 
                    :element-attr="{ class: 'btn-toolbar'}"
                    @click="rows('abre')" 
                    hint="Expandir Todo | Shift + Flecha Abajo">
                    <i class="bi bi-arrows-expand"></i>
                </DxButton>
            </DxItem>

            <DxItem
                location="before"
                :visible="!isVisibleTarea && !isVisibleProyecto  && !isVisiblePegarCalendar"
            >
                <DxButton 
                    :element-attr="{ class: 'btn-toolbar'}"
                    @click="rows('cierra')" 
                    hint="Cerrar Todo | Shift + Flecha Arriba">
                    <i class="bi bi-arrows-collapse"></i>
                </DxButton>
            </DxItem>

            <DxItem
                location="before"
            >
                <DxButton 
                    :element-attr="{ class: 'btn-toolbar'}"
                    @click="() => { showPieChart = !showPieChart }"
                    hint= "Mostrar/Ocultar Torta"
                >
                    <i class="bi bi-pie-chart"></i>
                </DxButton>
            </DxItem>

            <DxItem
                :visible="!isVisibleTarea && !isVisibleProyecto && !isVisiblePegarCalendar"
                location="before"
                locate-in-menu="auto"
                template="separatorTemplate"
                menu-item-template="menuSeparatorTemplate"
            />

            <DxItem
                location="before"
                :visible="!isVisibleTarea && !isVisibleProyecto  && !isVisiblePegarCalendar"
            >
                <DxButton 
                    :disabled="selectedData.length == 0"
                    :element-attr="{ class: 'btn-toolbar'}"
                    @click="() => { 
                        if (store.confirmDelete)
                            popupVisible = true 
                        else 
                            deleteSelected()
                        }" 
                    hint="Borrar Tareas Seleccionadas">
                    <i class="bi bi-trash3"></i>
                </DxButton>
            </DxItem>

            <DxItem
                :visible="!isVisibleTarea && !isVisibleProyecto && !isVisiblePegarCalendar"
                location="before"
                locate-in-menu="auto"
                template="separatorTemplate"
                menu-item-template="menuSeparatorTemplate"
            />

            <DxItem
                location="before"
                :visible="!isVisibleTarea && !isVisibleProyecto  && !isVisiblePegarCalendar"
                template="buttonCopiar">

            </DxItem>
            <DxItem
                location="before"
                :visible="!isVisibleTarea && !isVisibleProyecto  && !isVisiblePegarCalendar"
                template="buttonPegar">
            </DxItem>
            <DxItem
                location="before"
                :visible="!isVisibleTarea && !isVisibleProyecto  && !isVisiblePegarCalendar"
                template="buttonPegarValor">
            </DxItem>
            <DxItem
                location="before"
                :visible="(!isVisibleTarea && !isVisibleProyecto) || isVisiblePegarCalendar"
                template="buttonPegarCalendar">
            </DxItem>

            <DxItem
                location="before"
                :visible="isVisiblePegarCalendar"  
            >
                <DxDateBox
                    type="date"
                    :value="pegarDesde"
                    display-format="dd/MM/yyyy" 
                    label-mode="floating"
                    label="Desde"
                    :max="pegarHasta"
                    hint="Copiar desde esta fecha inclusive"
                    @value-changed="pegarDesdeChanged"
                >
                </DxDateBox>
            </DxItem>

            <DxItem
                location="before"
                :visible="isVisiblePegarCalendar"  
            >
                <DxDateBox
                    type="date"
                    :value="pegarHasta"  
                    display-format="dd/MM/yyyy" 
                    label-mode="floating"
                    label="Hasta"
                    :min="pegarDesde"
                    hint="Copiar hasta esta fecha inclusive"
                    @value-changed="pegarHastaChanged"
                >
                </DxDateBox>
            </DxItem>

            <DxItem
                location="before"
                :visible="isVisiblePegarCalendar"
            >
                <DxCheckBox
                    :value="copiarTiempo"
                    text="Copiar Hs"
                    hint="Copiar la carga horaria"
                    @value-changed="(e) => { copiarTiempo = e.value}"
                >

                </DxCheckBox>
            </DxItem>

            <DxItem
                location="before"
                :visible="isVisiblePegarCalendar"
            >
                <DxCheckBox
                    :value="finde"
                    text="Sáb/Dom"
                    hint="Copiar incluso Sáb o Dom"
                    @value-changed="(e) => { finde = e.value}"
                >

                </DxCheckBox>
            </DxItem>

            <DxItem
                location="before"
                :visible="isVisiblePegarCalendar"
            >
                <DxButton
                    text="Pegar"
                    type="success"
                    @click="onPegarCalendar"  
                >
                </DxButton>
            </DxItem>

            <DxItem
                location="before"
                :visible="isVisiblePegarCalendar"
            >
                <DxButton
                    text="Cancelar"
                    @click="onCancelarPegarCalendar"  
                >
                </DxButton>
            </DxItem>

            <DxItem
                :visible="!isVisibleTarea && !isVisibleProyecto && !isVisiblePegarCalendar"
                location="before"
                locate-in-menu="auto"
                template="separatorTemplate"
                menu-item-template="menuSeparatorTemplate"
            />

            <DxItem
                :visible="!isVisibleTarea  && !isVisiblePegarCalendar"
                location="before"
                template="buttonProyecto">

            </DxItem>

            <DxItem
                location="center"
                :visible="isVisibleProyecto"
                >
                <DxSelectBox
                    :data-source="cbProjects"
                    :value="defaultProject"
                    display-expr="text"
                    value-expr="ProjectIndex"
                    :element-attr="selectBoxAttributes"
                    :search-enabled="true"
                    :show-clear-button="true"
                    placeholder="Seleccionar Proyecto ..."
                    no-data-text="No hay datos ..."
                    @value-changed="onNewProjectChanged"
                >
                </DxSelectBox>
            </DxItem>
        
        </DxToolbar>
        <template #separatorTemplate>
            <div class="toolbar-separator"/>
        </template>

        <template #menuSeparatorTemplate>
            <div class="toolbar-menu-separator"/>
        </template>

        <template #iconProperty1>
            <i class="bi bi-coin"></i>
        </template>

        <template #iconProperty2>
            <i class="bi bi-sign-intersection-t"></i>
        </template>

        <template #buttonCopiar>
            <DxButton 
                :element-attr="{ class: 'btn-toolbar'}"
                @click="btnCopiar" 
                hint="Copiar">
                <i class="bi bi-clipboard"></i><span class="badge fondo-badge">{{ dataBadge }}</span>
            </DxButton>
        </template>

        <template #buttonPegar>
            <DxButton 
                :disabled="isPegarDisabled"
                :element-attr="{ class: 'btn-toolbar'}"
                @click="btnPegar(false)" 
                hint="Pegar">
                <i class="bi bi-clipboard-check"></i>
            </DxButton>
        </template>

        <template #buttonPegarValor>
            <DxButton 
                :disabled="isPegarDisabled"
                :element-attr="{ class: 'btn-toolbar'}"
                @click="btnPegar(true)" 
                hint="Pegar Valor">
                <i class="bi bi-clipboard2-data"></i>
            </DxButton>
        </template>

        <template #buttonPegarCalendar>
            <DxButton 
                :disabled="isPegarDisabled"
                :element-attr="{ class: 'btn-toolbar'}"
                @click="btnPegarFechas" 
                hint="Pegar en varias fechas">
                <i class="bi bi-calendar-week"></i>
            </DxButton>
        </template>

        <template #buttonProyecto>
            <DxButton 
                :element-attr="{ class: 'btn-toolbar'}"
                @click="changeisVisibleProyecto"
                :hint="hintProject">
                <i class="bi bi-folder-plus"></i>
                <span>
                    &nbsp;{{ btnProjectText }}
                </span>
            </DxButton>
        </template>

        <DxColumn 
            data-field="orden"
            caption=""
            :width=40
            cell-template="ordenTemplate"
            >
        </DxColumn>
            <template #ordenTemplate="{ data }">
                <i class="bi bi-folder-fill" :style="{ color: arrayColores[data.row.data.orden]}"></i>
            </template>

        <!-- css-class="cell-highlighted"  -->
        <DxColumn 
            data-field="desc"
            caption="Proyecto"

            cell-template="descTemplate"
        >
        </DxColumn>
        <template #descTemplate="{ data }">
            <div class="d-flex align-items-center">
                <div class="candado">
                    <i v-if="data.row.data.abierto == 0" class="bi bi-lock-fill"><br></i>
                </div>
                <div  class="cell-highlighted">
                    {{ data.row.data.desc }}
                </div>
            </div>
        </template>

        <DxColumn 
            data-field="tiempo"
            caption="Tiempo"
            :width="80"
            header-cell-template="header-time"
        >
            <DxSorting mode="none"/>
        </DxColumn>

        <template #header-time>
          <i class="bi bi-clock"></i>
        </template>

        <DxMasterDetail
            :enabled="true"
            :auto-expand-all="true"
            template="ExtDetailTemplate"
            v-on:sumaSelected="masSelected"
            />
            <template #ExtDetailTemplate="{ data: data}">
                <ExtDetailTemplate
                    :template-data="data"
                    :gridinstance="myDataGrid"
                    :donutinstance="myDonut"
                    :baseurl="baseUrl"
                    :user="user"
                    :hoy="hoy"
                    :items="items"
                    v-on:sumaSelected="masSelected"

                />
            </template>
            <!-- v-on:restaSelected="menosSelected" -->
    </DxDataGrid>
    </div>  <!-- Fin Grilla -->


    <DxPopup
      v-model:visible="popupVisible"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      :show-title="true"
      :width="500"
      :height="200"
      title="Eliminar Tareas Seleccionadas"
    >
      <DxPosition
        at="center"
        my="center"
        of="window"
        collision="fit"
      />

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="deleteButton"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="cancelButton"
      />
      <p>
        Eliminar las Tareas Seleccionadas ??
      </p>
    </DxPopup>
</div>

</template>

<script setup>
import { DxDataGrid, 
            DxColumn,
            DxSelection,
            DxToolbar,
            DxItem,
            DxSorting,
            DxScrolling,
            DxMasterDetail} from 'devextreme-vue/data-grid'

import { DxPieChart, DxSeries } from 'devextreme-vue/pie-chart'
import { DxSelectBox } from 'devextreme-vue/select-box'
import { DxCheckBox } from 'devextreme-vue/check-box'

import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'

import { DxButton } from 'devextreme-vue/button'
import { DxDateBox } from 'devextreme-vue/date-box'

import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup'

import notify from 'devextreme/ui/notify'

import ExtDetailTemplate from './DetailView.vue'

import { ref, onMounted } from 'vue'

import {useRoute} from 'vue-router'

//---- Localization
import esMessages from '../diccionario/es.json'
import { locale, loadMessages } from "devextreme/localization";

import store from '../store/index.js'

import moment from 'moment'

//---- Variables 

const popupVisible = ref(false)
const aceptaEliminar = ref(false)

const dataBadge = ref(null)

const isPegarDisabled = ref(true)

const showPieChart = ref(store.viewTorta == 1)

const user = store.usuario

const myDataGrid = ref(null)
const myDonut = ref(null)

const labelHoras = ref('')
const totalHorasChart = ref()

const ghost = ref(0)


let items = []
const sbItems = ref([])

const selectedData = ref([])
const dataToCopy = ref(null)

const deleteButton = {
  text: 'Eliminar',
  stylingMode: 'outlined',
  type: 'danger',
  onClick: () => {
    popupVisible.value = false
    deleteSelected()
  },
}

const cancelButton = {
  text: 'Cancelar',
  stylingMode: 'outlined',
  type: 'normal',
  onClick: () => {
    popupVisible.value = false
    aceptaEliminar.value = false
  },
}

function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
  
    return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)} hs`;
}
  
function padToTwoDigits(num) {
    return num.toString().padStart(2, '0');
}

const isVisibleProyecto = ref(false)
const isVisibleTarea = ref(false)
const isVisiblePegarCalendar = ref(false)

const btnProjectText = ref('Proyecto')
const btnTareaText = ref('Tarea')

const hintProject = ref('Agregar Proyecto')

const btnEstiloProyecto = ref('default')
const btnEstiloTarea = ref('default')

const defaultProject = ref(null)

const selectedItemKeys = ref([])

const selectBoxAttributes = {
    style: "min-width: 300px;",
    class: 'selectbox'
}
const baseUrl = 'https://iylotton6j.execute-api.sa-east-1.amazonaws.com/Desarrollo/'

const route = useRoute()

const routeId = route.params.fecha !== '' ? route.params.fecha + ' 00:00:00' : new Date().toISOString().split('T')[0] + ' 00:00:00'

const hoy = ref(new Date(routeId))

const pegarDesde = ref(null)
const pegarHasta = ref(null)
const copiarTiempo = ref(true)
const finde = ref(false)

let keypress = false 

const dias = [
  'Domingo',
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
]

const arrayColores = [
    '#AAABA8',
    '#845EC2',
    '#2C73D2',
    '#19B3EA',
    '#008E9B',
    '#00C9A7',
    '#F9F871',
    '#FFC75F',
    '#FF9671',
    '#FF6F91',
    '#BA2676',
    '#D65DB1',
    '#B39CD0'
]

function onKeyDown(e) {
    // console.log(e)
    if (e.ctrlKey)
        switch (e.key) {
            case 'c' || 'C':
                btnCopiar()
                break
            case 'v' || 'V':
                btnPegar(false)
                break
            case 'd' || 'D':
                btnPegar(true)
                break
            case 'w' || 'W':
                btnPegarFechas()
                break
        }
    if (e.shiftKey)
        switch (e.key) {
            case 'ArrowRight':
                cambiaDia(1)
                break
            case 'ArrowLeft':
                cambiaDia(-1)
                break
            case 'ArrowDown':
                rows('abre')
                break
            case 'ArrowUp':
                rows('cierra')
                break
        }

}

function onRowExpanding(e) {
    e.component.collapseAll(-1)
    store.isExpandingSwitch(1)
}

function onFocusedRowChanged() {

}

function rows(accion) {
    if (accion == 'abre') {
        store.isExpandingSwitch(myDataGrid.value.instance.totalCount())
        myDataGrid.value.instance.expandAll(-1)
    }
    else {
        myDataGrid.value.instance.collapseAll(-1)
    }
}

function pegarDesdeChanged(e) {
    pegarDesde.value = e.value
}


function pegarHastaChanged(e) {
    pegarHasta.value = e.value
}

async function deleteSelected() {
    selectedData.value.forEach( (key) => {
        fetch( baseUrl + 'deletetime?tsid=' + key.ts_Id, {
        method: "DELETE"
    })
    .then(handleErrors)
    .then(() => upedateamos())
    selectedData.value = []
    })
}

function btnCopiar() {
    // console.log(selectedData.value)
    if (selectedData.value.length == 0) {
        notify("No hay elementos seleccionados", "error", 600)
        isPegarDisabled.value = true
        dataBadge.value = null
        }
    else {
        isPegarDisabled.value = false
        dataToCopy.value = selectedData.value
        dataBadge.value = dataToCopy.value.length
    }
}
// var cantPaste = 0

function btnPegar(valor) {
    // cantPaste += 1
    // console.log('cantPaste: ',cantPaste)
    // console.log('length ',selectedData.value.length)
    if (dataToCopy.value.length == 0) {
        notify("P No hay elementos seleccionados para pegar ...", "error", 3000)
        }
    else {
        if (dataToCopy.value[0].ts_Fecha.split('T')[0] == hoy.value.toISOString().split('T')[0])
            notify("Estás pegando tareas de la misma fecha de la que copiaste ...", "error", 3000)
        else {
            dataToCopy.value.forEach( (key) => {
            let url = baseUrl + 'inserttime'
            let newBody = {
                usercognito: key.ts_UserCognito,
                userId: store.userId,
                fecha: hoy.value.toISOString().split('T')[0],
                projectid: key.ts_ProjectId,
                tipo: key.ts_Tipo,
                tareaid: key.tareaId,
                desc: key.tareadesc,
                property1: key.property1,
                property2: key.property2,
                minutos: valor ? key.tiempo : '00:00'
                }
            fetch(url,{
                method: 'POST',
                body: JSON.stringify(newBody)
            })
            .then(handleErrors)
            .then(() => {
                // console.log('paso por btnPegar y salio')
                upedateamos()
                // dataToCopy.value = null
                // dataBadge.value = null
                // isPegarDisabled.value = true
                })
            .catch(() => { throw 'Problema en la Conexión de Red' })
            })

            upedateamos()
            dataToCopy.value = []
            dataBadge.value = null
            isPegarDisabled.value = true
            selectedData.value = []

        }
        }

}

function btnPegarFechas() {
    if (dataToCopy.value.length == 0) {
        notify("No hay elementos seleccionados para pegar ...", "error", 3000)
        }
    else {
        let pegarFecha = new Date(dataToCopy.value[0].ts_Fecha.toString().slice(0, 23))
        pegarDesde.value = moment(pegarFecha).add(1,'d').toDate()
        pegarHasta.value = moment(pegarFecha).add(2,'d').toDate()
        isVisiblePegarCalendar.value = true
    }
}


async function processDataSequential(postFecha) {
    let url = baseUrl + 'inserttime'
    try {
        for (const key of dataToCopy.value) {
            const newBody = {
                usercognito: key.ts_UserCognito,
                userId: store.userId,
                fecha: postFecha.toISOString().split('T')[0],
                projectid: key.ts_ProjectId,
                tipo: key.ts_Tipo,
                tareaid: key.tareaId,
                desc: key.tareadesc,
                property1: key.property1,
                property2: key.property2,
                minutos: copiarTiempo.value ? key.tiempo : '00:00'
            };

            const response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(newBody)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        }
        
        await upedateamos();
        
    } catch (error) {
        console.error('Error en el proceso:', error);
        throw new Error('Problema en la Conexión de Red');
    }
}



function onPegarCalendar() {
    let postFecha = pegarDesde.value
    // let url = baseUrl + 'inserttime'
    // let newBody = {}
    let diaSemana
    while (postFecha <= pegarHasta.value) {
        diaSemana = moment(postFecha).isoWeekday()
        if (!finde.value && (diaSemana == 6 || diaSemana == 7))
            postFecha = moment(postFecha).add(1,'d')
        else {
            processDataSequential(postFecha)
            postFecha = moment(postFecha).add(1,'d')
        }
    }
    isVisiblePegarCalendar.value = false
    upedateamos()
    dataToCopy.value = []
    dataBadge.value = null
    isPegarDisabled.value = true
    selectedData.value = []
}

// function onPegarCalendar() {
//     let postFecha = pegarDesde.value
//     let url = baseUrl + 'inserttime'
//     let newBody = {}
//     let diaSemana
//     while (postFecha <= pegarHasta.value) {
//         diaSemana = moment(postFecha).isoWeekday()
//         if (!finde.value && (diaSemana == 6 || diaSemana == 7))
//             postFecha = moment(postFecha).add(1,'d')
//         else {
//             dataToCopy.value.forEach( (key) => {
//                 newBody = {
//                     usercognito: key.ts_UserCognito,
//                     userId: store.userId,
//                     fecha: postFecha.toISOString().split('T')[0],
//                     projectid: key.ts_ProjectId,
//                     tipo: key.ts_Tipo,
//                     tareaid: key.tareaId,
//                     desc: key.tareadesc,
//                     property1: key.property1,
//                     property2: key.property2,
//                     minutos: copiarTiempo.value ? key.tiempo : '00:00'
//                     }
//                 fetch(url,{
//                     method: 'POST',
//                     body: JSON.stringify(newBody)
//                 })
//                 .then(handleErrors)
//                 .then( upedateamos())
//                 .catch(() => { throw 'Problema en la Conexión de Red' })
//                 })
//             postFecha = moment(postFecha).add(1,'d')
//         }
//     }
//     isVisiblePegarCalendar.value = false
//     upedateamos()
//     dataToCopy.value = []
//     dataBadge.value = null
//     isPegarDisabled.value = true
//     selectedData.value = []
// }

function onCancelarPegarCalendar() {
    isVisiblePegarCalendar.value = false
}

function onShowing() {
}

function handleErrors(response) {
    if (!response.ok) {
        throw Error('EW'+response.statusText)
    }
    return response;
}

function upedateamos() {
    myDataGrid.value.instance.refresh()
    myDataGrid.value.instance.collapseAll()
    if (showPieChart.value)
        myDonut.value.instance.getDataSource().reload()
    getRestProjects()
}

const storeDataSource = new CustomStore({
    key: ['ts_ProjectId','ts_Tipo', 'ts_Fecha', 'ts_UserCognito'],
    load: () => {
        let fec = hoy.value.toISOString().split('T')[0]
        let url = baseUrl + 'times?desde=' + fec +
                    '&hasta=' + fec +
                    '&usuario=' + user +
                    '&title=1'
        return fetch(url)
        .then(handleErrors)
        .then(response => response.json())
        .catch(() => { throw 'Problema en la Conexión de Red'})
    },
    insert: (value) => {
        fetch(baseUrl + 'inserttime', {
        method: 'POST',
        body: JSON.stringify(value)
        })
        .then(handleErrors)
        .then(() => upedateamos())
        .catch(err => { notify({ message: 'Error en la Red: '+err.message, position: "center", direction: "up-push" }, 'error',5000) })

    },
    onLoaded: (data) => {
        totalHorasChart.value = 0
        for (var i = 0; i < data.length; i++) {
            var pedazos = data[i].tiempo.split(':')
            totalHorasChart.value +=  (Number(pedazos[0])*60) + Number(pedazos[1])
            }
        labelHoras.value = toHoursAndMinutes(totalHorasChart.value)
    }
})

const masterDataSource = new DataSource({
    store: storeDataSource
})

// ------- Donught

function customizePoint(e) {
    return { color: arrayColores[e.data.orden]}
}


const storeDonut = new DataSource({
    store: new CustomStore({
        key: 'ts_ProjectId',
        load: () => {
            let fec = hoy.value.toISOString().split('T')[0]
            let url = baseUrl + 'getdonut/?fecha=' + fec + '&user=' + user
            return fetch(url)
            .then(response => response.json())
            .catch(() => { throw 'Problema en la Conexión de Red'})
        }
        })
    })

function cambiaDia(nDias) {
    ghost.value = ghost.value + nDias
    if (myDataGrid.value) {
        myDataGrid.value.instance.collapseAll(-1)
    }
    hoy.value.setDate(hoy.value.getDate() + nDias)
    getRestProjects()
    myDataGrid.value.instance.refresh()
    if (showPieChart.value)
        myDonut.value.instance.getDataSource().reload()
}


// ---- Agregar Proyecto

// ----- Trae Resto de Proyectos
const cbProjects = ref() 

function getRestProjects() {
    let fec = hoy.value.toISOString().split('T')[0]
    let url =  baseUrl + 'getrestprojects?fecha=' + fec +
                         '&user=' + user
    console.log(url)
    cbProjects.value = new DataSource({ 
    store: new CustomStore({
        loadMode: 'raw',
        key: 'ProjectIndex',
        load: () => {
        return fetch(url)
            .then(handleErrors)
            .then(response => response.json())
            .catch(() => { throw 'Problema en la Conexión de Red'})
    }
    })
})
}

function onNewProjectChanged(e) {
    if (e.value == null) {
        if (isVisibleProyecto.value) {
            btnProjectText.value = 'Cancelar'
            hintProject.value = 'Cancelar Agregar Proyecto'
            btnEstiloProyecto.value = 'danger'
            }
        else {
            btnProjectText.value = 'Proyecto'
            hintProject.value = 'Agregar Proyecto'
            btnEstiloProyecto.value = 'default'
        }
    }
    else {
    btnProjectText.value = 'Agregar'
    hintProject.value = 'Aceptar y Grabar Proyecto'
    btnEstiloProyecto.value = 'success'
    }
    defaultProject.value = e.value
}

function changeisVisibleProyecto() {
    if (isVisibleProyecto.value && defaultProject.value !== null) {
        insertProject()
        defaultProject.value = null
        }
    isVisibleProyecto.value = !isVisibleProyecto.value
    if (isVisibleProyecto.value) {
        // Por las dudas que estuviera activo Buscar Tarea
        isVisibleTarea.value = false
        btnTareaText.value = 'Tarea'
        btnEstiloTarea.value = 'default'
        //
        btnProjectText.value = 'Cancelar'
        hintProject.value = 'Cancelar Agregar Proyecto'
        btnEstiloProyecto.value = 'danger'
    }
    else {
        btnProjectText.value = 'Proyecto'
        hintProject.value = 'Agregar Proyecto'
        btnEstiloProyecto.value = 'default'
    }
}

async function insertProject() {
    let proyecto = defaultProject.value.slice(0,-1)
    let tarea = defaultProject.value.slice(-1) == 'y' ? 18 : 1
    let facturable = defaultProject.value.slice(-1) == 'y' ? 1 : 0

    let newBody = {
        usercognito: user,
        userId: store.userId,
        fecha: hoy.value.toISOString().split('T')[0],
        projectid: proyecto,
        tipo: defaultProject.value.slice(-1),
        tareaid: tarea,
        desc: '',
        property1: facturable,
        property2: 0,
        minutos: '00:00'
        }

    if (proyecto == 204) {
        tarea = 3
        await fetch(baseUrl + 'gettarea/3')
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            newBody.tareaid = 3
            newBody.property1 = data[0].taProperty1
            newBody.property2 = data[0].taProperty2
        })
        .catch(err => { console.log('fetch failed #1', err.message) })
        }
    if (proyecto == 205) {
        tarea = 6
        try {
        await fetch(baseUrl + 'gettarea/6')
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            newBody.tareaid = 6
            newBody.property1 = data[0].taProperty1
            newBody.property2 = data[0].taProperty2
        })
        .catch(err => { notify('Error en la Red: ', err.message) })
        } catch(err) { notify(err.message) }
        }
    await storeDataSource.insert(newBody)
}



function getItems() {
    if (!sbItems.value.length) {
    let url = baseUrl + 'gettareas?project=0'
    return fetch(url)
    .then(handleErrors)
    .then(response => response.json())
    .then(data => {
        items = data
        sbItems.value = data})
    .catch(() => { throw 'Problema en la Conexión de Red' + url})
    }
}

function masSelected(selArray, deselArray, selData) {
    // dataToCopy.value = null
    // dataBadge.value = null
    // isPegarDisabled.value = true
    // obj.forEach( item => {
    //     selectedData.value.push(item)
    // })

    if (selArray) // Es que hizo Deselect
        selArray.forEach(arrayElement => {
            selectedData.value.push( selData.filter(item => item.ts_Id == arrayElement)[0])
        })
    if (deselArray )
        deselArray.forEach(arrayElement => {
            selectedData.value = selectedData.value.filter( item => item.ts_Id != arrayElement)
        })
}

// function menosSelected(arr) {
//     dataToCopy.value = null
//     dataBadge.value = null
//     isPegarDisabled.value = true
//     let index = 0
//     arr.forEach( id => {
//         index = selectedData.value.findIndex( x => x.ts_Id === id)
//         selectedData.value.splice(index, 1)
//     })
// }

function buscaUser(id) {
    let url = baseUrl + 'user/' + id
    // console.log('BuscaUser: ',store.userId)
    fetch(url)
        .then(handleErrors)
        .then(response => response.json())
        .then( data => {
            store.userTimeTipoSetValue(data[0].us_ts_TimeTipo)
            store.userRowModeSetValue(data[0].us_ts_RowMode)
            store.userConfirmDeleteSetValue(data[0].us_ts_ConfirmDelete)
            store.userTimeIntervalSetValue(data[0].us_ts_TimeInterval)
            store.userViewTortaSetValue(data[0].us_ts_ViewTorta)
            if (data[0].us_AreaId == null) {
                notify({ message: "Debes completar tus datos de Perfil de Usuario",  shading: true, displayTime: 3500 }, { position: "center", direction: "up-push" })
                notify({ message: "Click en tu Nombre de Usuario -> Datos del Usuario", shading: true, displayTime: 4000 }, { position: "center", direction: "up-push" })
            }
        })
        .catch(() => { throw 'Problema en la Conexión de Red Home-View BuscaUser'})
}


onMounted( async () => {
    store.titleSetValue('TimeSheet')

    window.addEventListener('keydown', function(e) {
        if (!keypress) {
            keypress = true
            onKeyDown(e)
            // console.log('presione ',e.key)
        } else {
            return
        }
    })
    window.addEventListener('keyup', function() {
        keypress = false
        // console.log('libere ',e.key)
        })


    buscaUser(store.userId)

    loadMessages(esMessages)
    locale(navigator.language)
    cambiaDia(0)
    getItems()
})


</script>

<style>

body {
    width: 100%;
}

.btn-outline {
  color: rgb(243,112,32);
  background-color: #ffffff;
  border-color: rgb(243,112,32);
  font-weight: bold;
  letter-spacing: 0.05em;
}

.btn-outline:hover,
.btn-outline:active,
.btn-outline:focus,
.btn-outline.active {
  background: rgb(243,112,32);
  color: #ffffff;
  border-color: rgb(243,112,32);
}

.btn-toolbar {
  color: rgb(119, 121, 131);
  background-color: #ffffff;
  border-color: rgb(243,112,32);
}

.btn-toolbar:hover,
.btn-toolbar:active,
.btn-toolbar:focus,
.btn-toolbar.active {
  background: rgb(243,112,32);
  color: #ffffff;
  border-color: rgb(243,112,32);
}

.fondo-badge {
    background: rgb(243,112,32);
    color: #ffffff;
    margin-left: 5px;
}



.dia {
    font-size: 2rem;
}
.grilla {
    display: flex;
    justify-content: center;
    align-items: center;
}
.centertext {
  font-size: 36px;
  font-weight: 400;
}
.ghostdata {
    display: none;
}

.dx-datagrid .dx-link[aria-label="Guardar"] {
  color: green;
}
.dx-datagrid .dx-link[aria-label="Eliminar"] {
  color: red;
}
.dx-datagrid .dx-link[aria-label="Modificar"] {
  color: blue;
}
.dx-datagrid .dx-link[aria-label="Cancelar"] {
  color: rgb(123, 119, 119);
}

#datagrid {
  height: 100%;
}

.grilla {
    height: 600px;
}

@media (height <= 600px) {

}
.toolbar-menu-separator {
  height: 1px;
  border-bottom: 1px solid #ddd;
}

.toolbar-separator {
  height: 36px;
  margin: 0 5px;
  border-left: 1px solid #ddd;
}

.dx-data-row .cell-highlighted {
    font-size: 1.1rem;
    margin-left: 10px;
}

.candado {
    color: rgb(243,112,32);
}
</style>