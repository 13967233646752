<template>

    <DxDataGrid
        ref="myDataGrid"
        :data-source="dataSource"
        @row-updating="updateRow"
        :repaint-changes-only="true"
        @editing-start="onEditingStart"
        @row-removing="onRowRemoving"
        :swipeEnabled="true"
        @saving="onSaving"
        @cell-hover-changed="onCellHoverChanged"
        :sorting="{ mode: 'none'}"
        @selection-changed="onSelectionChanged"
        :focused-row-enabled="false"
        @init-new-row="onNewRow"
        @editor-preparing="onEditorPreparing"
        >

        <DxScrolling
            show-scrollbar="true"    
        >
        </DxScrolling>

        <DxSelection 
          mode="multiple"
          show-check-boxes-mode="onClick"
        /> 

        <DxColumn
          data-field="tareaId"
          caption="Tarea"
          data-type="number"
          :allow-editing="true"
          :show-editor-always="true"
        >
          <DxLookup
            :data-source="cbTareas"
            value-expr="ta_Id"
            display-expr="ta_Nombre"
          />

          <DxValidationRule type="required"/>
        </DxColumn>

        <DxColumn 
          :visible="!tiempoDecimal"
          data-field="tiempo"
          :width=80
          data-type="datetime"
          :show-editor-always="true"
          :editor-options="edOpt"
          header-cell-template="title-header-time">
        </DxColumn>
        <DxColumn 
          :visible="tiempoDecimal"
          data-field="tiempoInt"
          :width=80
          data-type="number"
          :show-editor-always="true"
          :editor-options="edOptDecimal"
          header-cell-template="title-header-time">
        </DxColumn>
        <template #title-header-time>
          <div class="horaTitulo">
            <i class="bi bi-clock  text-center"></i>
          </div>
        </template> 
        <DxColumn
          data-field="property1"
          data-type="boolean"
          :width=50
          header-cell-template="title-header-money"
          >
        </DxColumn>
        <template #title-header-money>
          <i class="bi bi-coin"></i>
        </template>
        <DxColumn
          data-field="property2"
          data-type="boolean"
          :width=50
          header-cell-template="title-header-terrain"
          >
        </DxColumn>
        <template #title-header-terrain>         
          <i class="bi bi-sign-intersection-t"></i>
        </template>
        <DxColumn
          data-field="tareadesc"
          caption="Descripción"
          data-type="string"
          >
        </DxColumn>
        <DxColumn type="buttons">
        <DxButton
          icon="add"
          @click="onAddButtonClick"
        />
        <DxButton name="edit"/>
        <DxButton name="delete"/>
        <DxButton name="save"/>
        <DxButton name="cancel"/>
      </DxColumn>
        <DxEditing
          :allow-updating="allowEdicion"
          :allow-deleting="allowEdicion"
          :confirm-delete="userConfirmDelete"
          :texts="localizedEditTexts"
          :use-icons="true"
          refresh-mode="repaint"
          :select-text-on-edit-start="true"
          :mode="rowEditModeType[1]">
        </DxEditing>
    </DxDataGrid>  

    <DxTooltip
      :target="TooltipTarget"
      :visible="isToolTipVisible"
      content-template="toolTipTemplate"
      >
      <template #toolTipTemplate>
        <span>{{ TooltipText }}</span>
      </template>
    </DxTooltip>

</template>

<script setup>

import {  DxDataGrid,
          DxColumn,
          DxEditing, 
          DxSelection,
          DxScrolling, 
          DxButton,
          DxValidationRule,
          DxLookup, 
        } from 'devextreme-vue/data-grid';

import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'

import { DxTooltip } from 'devextreme-vue/tooltip'

import dxCheckBox from 'devextreme/ui/check_box'

import { ref } from 'vue'
import store from '../store/index'

const userConfirmDelete = store.confirmDelete == 1 ? true : false

const isToolTipVisible = ref(false)
const TooltipTarget = ref(null)
const TooltipText = ref(null)

const myDataGrid = ref()
const updateObject = ref()

const rowEditModeType = [
  'row',
  'cell' 
]

const tiempoDecimal = store.timeTipo == 1

const edOpt = {
  'type': "time",
  'pickerType': "list",
  'displayFormat': "HH:mm",
  'interval': store.timeInterval,
}

const edOptDecimal = {
  'type': "number",
  'format': "00.00",
}

const props = defineProps({
    templateData: { type: Object },
    gridinstance: { type: Object },
    donutinstance: { type: Object},
    baseurl: { type: String},
    user: { type: String },
    hoy: { type: Date },
    items: {type: Object},
    selectedData: { type: Array},
})

const selectedItemKeys = ref(null)

const localizedEditTexts = {
    confirmDeleteMessage: '¿Esta seguro que desea eliminar esta información?'
}

const siTareaId = ref(false)

function onNewRow(e) {
  e.data.ts_ProjectId = selectedItemKeys.value.ts_ProjectId
  e.data.ts_Tipo = selectedItemKeys.value.ts_Tipo
  e.data.ts_Fecha = selectedItemKeys.value.ts_Fecha
  e.data.ts_UserCognito = selectedItemKeys.value.ts_UserCognito
  e.data.tiempoInt = 0
  e.data.tiempo = ''
  e.data.property1 = 0
  e.data.property2 = 0
  e.data.tareadesc = ''
}

function onAddButtonClick(e) {
  selectedItemKeys.value = e.row.data
  myDataGrid.value.instance.addRow()

}


function onCellHoverChanged(e) {
  if (e.rowType === 'header'  && e.column.dataField === 'tiempo'){
    TooltipTarget.value = e.cellElement
    if (e.eventType === 'mouseover') {
        TooltipText.value = 'Horas'
        isToolTipVisible.value = true
    }
    if (e.eventType === 'mouseout') {
        TooltipText.value = ''
        isToolTipVisible.value = false
    }
  }
  if (e.rowType === 'header'  && e.column.dataField === 'tiempoInt'){
    TooltipTarget.value = e.cellElement
    if (e.eventType === 'mouseover') {
        TooltipText.value = 'Horas'
        isToolTipVisible.value = true
    }
    if (e.eventType === 'mouseout') {
        TooltipText.value = ''
        isToolTipVisible.value = false
    }
  }
  if (e.rowType === 'header'  && e.column.dataField === 'property1'){
    TooltipTarget.value = e.cellElement
    if (e.eventType === 'mouseover') {
        TooltipText.value = 'Facturable'
        isToolTipVisible.value = true
    }
    if (e.eventType === 'mouseout') {
        TooltipText.value = ''
        isToolTipVisible.value = false
    }
  }
  if (e.rowType === 'header'  && e.column.dataField === 'property2'){
    TooltipTarget.value = e.cellElement
    if (e.eventType === 'mouseover') {
        TooltipText.value = 'en Terreno'
        isToolTipVisible.value = true
    }
    if (e.eventType === 'mouseout') {
        TooltipText.value = ''
        isToolTipVisible.value = false
    }
  }
}

function onSaving() {
  store.isExpandingSwitch(props.gridinstance.instance.totalCount())
}

function allowEdicion(e) {
  return !e.row.data.autorizada 
}


function onEditingStart(e) {
  store.rowExpandingSetValue([e.data.ts_ProjectId, e.data.ts_Tipo, e.data.ts_Fecha, e.data.ts_UserCognito])
}


function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}


function upedateamos() {
  let ds = props.gridinstance.instance
  ds.refresh()
  let di = props.donutinstance.instance.getDataSource()
  di.reload()
}


const cbTareasLoad = new CustomStore({
    key: 'ta_Id',
    loadMode: 'raw',
    load: () => {
      let url = props.baseurl + 'crudTarea'
      return fetch(url)
      .then(handleErrors)
      .then(response => response.json())
      .catch(() => { throw 'Problema en la Conexión de Red'})
    },
  }) 

function getFilter(e) {
  let devuelve = ''
  let numeroUsuario = store.userId
  let projectExclusive = [204,205,379,386]
  if (e.data)

    if (projectExclusive.includes(e.data.ts_ProjectId)) 
      devuelve = [
        [['ta_Exclusive','=',e.data.ts_ProjectId],'and',['ta_UserId','=',null]],
        'or',
        [['ta_Exclusive','=',e.data.ts_ProjectId],'and',['ta_UserId','=',numeroUsuario]]
      ]
    else
      devuelve = [
        [['ta_ProjectId','=',null],'and',['ta_Exclusive','=',null]],
        'or',
        [['ta_ProjectId','=',e.data.ts_ProjectId]]
      ]
  return devuelve
}

const cbTareas = (e) => ({
  store: cbTareasLoad,
  filter: e ? getFilter(e) : null
})


const detailStore = new CustomStore({
  key: 'ts_Id',
  byKey: async (keyVal) => {
      try {
      const response = await fetch(props.baseurl + 'gettarea/' + keyVal);
      return await response.json();
    } catch {
      throw 'Problema en la Conexión de Red';
    }
    },
  load: () => {
    let fec = props.hoy.toISOString().split('T')[0]
    let url = props.baseurl + 'times?desde=' + fec +
                              '&hasta=' + fec +
                              '&usuario=' + props.user +
                              '&title=0'
    return fetch(url)
    .then(handleErrors)
    .then(response => response.json())
    .catch(() => { throw 'Problema en la Conexión de Red'})
  },
  update: (key) => {

    let tiempoHoras = ''
    if (tiempoDecimal)
      tiempoHoras = Math.floor(updateObject.value.tiempoInt).toString().padStart(2,'0') + ':' +
      (Math.floor((updateObject.value.tiempoInt-Math.floor(updateObject.value.tiempoInt))*60)).toString().padStart(2,'0')
    else
      tiempoHoras = updateObject.value.tiempo

    var newBody = {
      tareaId: updateObject.value.tareaId,
      minutos: tiempoHoras,
      desc: updateObject.value.tareadesc,
      property1: updateObject.value.property1,
      property2: updateObject.value.property2,
      tsId: key
    }

    if (siTareaId.value) {
      detailStore.byKey(updateObject.value.tareaId)
      .then(data => {
        newBody.property1 = updateObject.value.ts_Tipo == 'p' ? 0 : data[0].taProperty1
        newBody.property2 = data[0].taProperty2
      })
      .then(() => fetch(props.baseurl + "update", {
              method: 'PUT',
              body: JSON.stringify(newBody)
              })
            .then(handleErrors)
            .then( ()=> upedateamos())
            .catch( () => {} ))
    }
    else {
    return fetch(props.baseurl + "update", {
        method: 'PUT',
        body: JSON.stringify(newBody)
    })
    .then(handleErrors)
    .then( ()=> upedateamos())
    .catch( () => {} )
    }

  },
  insert: (value) => {
    let tiempoHoras = ''
    if (tiempoDecimal)
      tiempoHoras = Math.floor(value.tiempoInt).toString().padStart(2,'0') + ':' +
      (Math.floor((value.tiempoInt-Math.floor(value.tiempoInt))*60)).toString().padStart(2,'0')
    else
      tiempoHoras = value.tiempo

    let newBody = {
      usercognito: value.ts_UserCognito,
      userId: store.userId,
      fecha: value.ts_Fecha,
      projectid: value.ts_ProjectId,
      tipo: value.ts_Tipo,
      tareaid: value.tareaId,
      desc: value.tareadesc,
      property1: value.property1,
      property2: value.property2,
      minutos: tiempoHoras
    }

    detailStore.byKey(value.tareaId)
      .then(data => {
        newBody.property1 = data[0].taProperty1
        newBody.property2 = data[0].taProperty2
      })
      .then(() => fetch(props.baseurl + "inserttime", {
              method: 'POST',
              body: JSON.stringify(newBody)
              })
            .then(handleErrors)
            .then( ()=> upedateamos())
            .catch( () => {} ))

    // fetch(props.baseurl + 'inserttime', {
    // method: 'POST',
    // body: JSON.stringify(newBody)
    // })
    // .then(handleErrors)
    // .then(() => upedateamos())
    // .catch(() => { throw 'Problema en la Conexión de Red' })
  },
  remove: (key) => {
    return fetch( props.baseurl + "deletetime?tsid="+key, {
        method: "DELETE"
    })
    .then(handleErrors)
    .then( ()=> upedateamos())
}
})

const dataSource = new DataSource({
  store: detailStore,
  filter: [
    ['ts_ProjectId', '=', props.templateData.data.ts_ProjectId],
    'and',
    ['ts_Tipo', '=', props.templateData.data.ts_Tipo]
  ]
}) 

function updateRow(e) { 
  siTareaId.value = Object.prototype.hasOwnProperty.call(e.newData,'tareaId')
  if (!e.cancel) {
      for (var property in e.oldData) {
        if (!Object.prototype.hasOwnProperty.call(e.newData,property)) {
          e.newData[property] = e.oldData[property];
        }    
      }
      updateObject.value = e.newData
      if (typeof updateObject.value.tareaId === 'object')
        updateObject.value.tareaId = updateObject.value.tareaId.taID
  }  
}

function onRowRemoving() {
  store.isExpandingSwitch(props.gridinstance.instance.totalCount())
}

const emit = defineEmits(['sumaSelected'])

// function selectionChanged(e) {
    // console.log(myDataGrid.value.instance.getSelectedRowKeys())
  // if (e.currentSelectedRowKeys.length > 0)
  //   emit('sumaSelected', e.selectedRowsData)
  // else
  //   emit('restaSelected', e.currentDeselectedRowKeys)
  // console.log(e)
  // emit('sumaSelected', myDataGrid.value.instance.getSelectedRowsData())
  // emit('sumaSelected', e.currentSelectedRowKeys, e.currentDeselectedRowKeys, e.selectedRowsData)
  // emit('cambioSeleccion', {
  //   masterRowKey: 308,
  //   selectedRows: e.component.getSelectedRowsData()
  // })
// }

let selectAllCheckBox = dxCheckBox
let checkBoxUpdating = false

const isSelectable = (item) => {
  return item.abierto 
}

const isSelectAll = (dataGrid) => {
  const selectableItems = []
  const selectedRowKeys = dataGrid.option("selectedRowKeys")
  dataGrid.getDataSource().items().forEach((item) => {
    if (isSelectable(item)) selectableItems.push(item)
    })
  if (!selectedRowKeys || !selectedRowKeys.length) {
    return false
    }
  return selectedRowKeys.length >= selectableItems.length ? true : undefined;
}

function onEditorPreparing(e) {

  if (e.type !== "selection") return

  if (e.parentType === "dataRow" && e.row && !isSelectable(e.row.data))
    e.editorOptions.disabled = true

  if (e.parentType === "headerRow") {
    const dataGrid = e.component
    e.editorOptions.value = isSelectAll(dataGrid)
    e.editorOptions.onInitialized = (e) => {
      if (e.component) 
        selectAllCheckBox = e.component
      }
    e.editorOptions.onValueChanged = (e) => {
      if (!e.event) {
        if (e.previousValue && !checkBoxUpdating) {
          e.component.option("value", e.previousValue)
        }
        return
      }

      if (isSelectAll(dataGrid) === e.value) {
        return
      }

      e.value ? dataGrid.selectAll() : dataGrid.deselectAll()
      e.event.preventDefault()
    }
  }

}

const onSelectionChanged = (e) => {
  emit('sumaSelected', e.currentSelectedRowKeys, e.currentDeselectedRowKeys, e.selectedRowsData)

  const deselectRowKeys = []
  const dataGrid = e.component
  e.selectedRowsData.forEach((item) => {
    if (!isSelectable(item)) deselectRowKeys.push(dataGrid.keyOf(item))
    });
  if (deselectRowKeys.length) {
    dataGrid.deselectRows(deselectRowKeys)
    }
  checkBoxUpdating = true
  selectAllCheckBox && selectAllCheckBox.option("value", isSelectAll(dataGrid))
  checkBoxUpdating = false
}
</script>

<style>
.dx-datagrid .dx-link[aria-label="Save"] {
  color: green;
}
.dx-datagrid .dx-link[aria-label="Delete"] {
  color: red;
}
.dx-datagrid .dx-link[aria-label="Edit"] {
  color: blue;
}
.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {  
  padding: 0px 0px 0px 0px;
}

.grid-class {
    font-size: 0.9rem;
}

</style>